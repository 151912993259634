import React from 'react';
import { motion } from 'framer-motion';

import {
    Box,
    Typography,
    Grid,
} from '@mui/material';

import Me from '../Images/me.jpeg'
import bkgrd from '../Images/code-back.jpg'

const AboutSection = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: {xs: 'none', md: 'none'}, // Adjust the height as needed
        boxSizing: 'border-box',
        overflow: 'hidden',
        backgroundColor: '#d9d1c8',
        //mb: 10, 
        px: {xs: 2, md: 2},
        pt: {xs: 10, md: 10},
        pb: {xs: 10, md: 10},
        //zIndex: 20,
      }}
      id="about-section"
    >
    
            <Grid container >
                <Grid item xs={12} >
                    <Typography
                        sx={{
                            textAlign: {xs: "center", md: "left"},
                            color: 'white',
                            fontSize: {
                                xs: '5rem',
                                sm: '8rem',
                                md: '7.5rem',
                                lg: '9rem',
                                xl: '9rem'
                            },
                            fontFamily: 'Montserrat',
                            fontWeight: 900,
                            lineHeight: .82,
                            letterSpacing: {xs:0, md: '-0.03em'},
                        }}
                    >
                       MYLES
                    </Typography>
                </Grid>
                <Grid item xs={12} >
                    <Typography
                        sx={{
                            textAlign: {xs: "center", md: "left"},
                            color: 'white',
                            fontSize: {
                                xs: '5rem',
                                sm: '8rem',
                                md: '7.5rem',
                                lg: '9rem',
                                xl: '9rem'
                            },
                            fontFamily: 'Montserrat',
                            fontWeight: 900,
                            lineHeight: .82,
                            letterSpacing: {xs:0, md: '-0.03em'},
                        }}
                    >
                        DAVIS
                    </Typography>
                </Grid>

                
                <Grid item xs={12} md={12} sx={{ mt: {xs: 8, md: 5, lg: 0} }}>
                    <Box
                        component="img"
                        src={bkgrd}
                        alt="MylesPicture"
                        align="center"
                        sx={{
                            width: { xs: "290px", sm: "450px", md: "400px", lg: "500px", xl: "500px"},
                            height: { xs: "250px", sm: "300px", md: "325px", lg: "375px", xl: "375px"},
                            display: 'flex',
                            //justifyContent: 'center', 
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    />
                       <Box
                        component="img"
                        src={Me}
                        alt="MylesPicture"
                        align="center"
                        sx={{
                            width: { xs: "250px", sm: "325px", md: "325px", lg: "400px", xl: "400px"},
                            height: { xs: "325px", sm: "400px", md: "400px", lg: "510px", xl: "510px"},
                            display: 'flex',
                            //justifyContent: 'center', 
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            zIndex: 10,
                            mt: { xs: -36, sm: -43, md: -45, lg: -55, xl: -55},
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={8}/>
               
                <Grid item xs={12} md={4} 
                    sx={{
                        pl: {xs: 2, sm: 5,  md: 7},  
                        pr: {xs: 2, sm: 5, md: 2},  
                        mt: {xs: 3, md: -30, lg: -37},
                        //pb: {xs: 5, md: 0, lg: 0}
                    }}
                >
                    <Typography
                        
                        sx={{
                            textAlign: {xs: "center", md: "left"},
                            color: 'black',
                            fontSize: {
                                xs: '1.6rem',
                                sm: '2rem',
                                md: '1.3rem',
                                lg: '1.5rem',
                                xl: '1.5rem'
                            },
                            fontFamily: 'Montserrat',
                            fontWeight: 'bold',
                            mb: .5,
                            //ml: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0},
                            //mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0},
                        }}
                    >
                        Hi, I'm Myles. 
                       
                    </Typography>
                    <Typography
                        
                        sx={{
                            textAlign: {xs: "center", md: "left"},
                            color: 'black',
                            fontSize: {
                                xs: '1.1rem',
                                sm: '1.4rem',
                                md: '1rem',
                                lg: '1.2rem',
                                xl: '1.2rem'
                            },
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            //ml: { xs: 0, sm: 0, md: 2, lg: 0, xl: 0},                            
                        }}
                    >
                        
                        While studying computer science, I journeyed into the realm of web development. 
                        Following my graduation, I focused on my professional career as a full-stack software developer, 
                        specializing in the creation and design of websites and applications with the aim of empowering clients to expand new capabilities.
                        With 5+ years of experience, 
                        lets craft captivating websites that embody your brand essence
                    </Typography>
                </Grid>
            </Grid>
        
    </Box>
    
  );
};

export default AboutSection;
