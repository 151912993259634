import "./style.css";
import React, { useState, useRef, useEffect } from 'react';
import { 
    Dialog, 
    DialogContent, 
    Typography,
    Grid,
    Card,
    CardMedia,
    IconButton,
    Box,
} from '@mui/material';
import {motion} from "framer-motion";
 

const CarouselSection = () => {
    const imageContext = require.context('../../../Images/OldMammoth', false, /\.(png|jpe?g|svg)$/);
    const images = imageContext.keys().map(imageContext);
    //console.log("Imgs:", images);
    const [width, setWidth] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const carousel = useRef();

    useEffect(() => {
        setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth)
    }, [])

    const handleDotClick = (index) => {
        setActiveIndex(index);
        carousel.current.scrollTo({
            left: index * (carousel.current.scrollWidth / images.length),
            behavior: 'smooth',
        });
    };

    return (
      <motion.div ref={carousel} className='carousel' whileTap={{cursor: 'grabbing'}}>
        <motion.div 
            drag='x' 
            dragConstraints={{right: 0, left: -width - 100}} 
            className='inner-carousel'
        >
            {images.map((image, index) => {
                return(
                    <motion.div 
                        //className='item' 
                        key={index} 
                    >
                        <img 
                            src={image} 
                            alt='' 
                            style={{ 
                                width: '800px', 
                                height: '500px', 
                                margin: '40px',
                                pointerEvents: 'none',
                                borderRadius: '2rem',
                                border: '3px solid black'
                            }} // Adjust these values as needed
                        />
                    </motion.div>
                )
            })}
            
        </motion.div>
        <div className="dot-container">
            {images.map((_, index) => (
                <div
                    key={index}
                    className={`dot ${index === activeIndex ? 'active' : ''}`}
                    onClick={() => handleDotClick(index)}
                />
            ))}
        </div>
      </motion.div>
    );
};

export default CarouselSection;
