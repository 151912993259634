import React, { useEffect, useRef } from "react";
import {
    useMotionTemplate,
    useMotionValue,
    motion,
    animate,
    useScroll,
    useTransform,
} from "framer-motion";

import {
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Avatar,
    Button,
    Tooltip,
    MenuItem,
    MenuList,
    Popover,
    List,
    ListItem,
    ListItemText,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    Grid,
    Drawer,
    Hidden
} from '@mui/material';

const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

const HomeLandingSection = () => {
    const ref = useRef(null);
    const {scrollYProgress} = useScroll({
      target: ref,
      offset: ["start start", "end start"],
    });
    const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "120%"]);
  
    const color = useMotionValue(COLORS_TOP[0]);

    const blinkingGreenLightStyle = {
        width: '15px',
        height: '15px',
        borderRadius: '50%',
        backgroundColor: 'green',
        animation: 'blink-green 2.5s infinite',
        marginRight: '10px',
        marginTop: '-3px',
    };

    const blinkingAnimation = `
        @keyframes blink-green {
            0% {
            opacity: 0;
            }
            50% {
            opacity: 1;
            }
            100% {
            opacity: 0;
            }
        }
    `;

    useEffect(() => {
        animate(color, COLORS_TOP, {
          ease: "easeInOut",
          duration: 10,
          repeat: Infinity,
          repeatType: "mirror",
        });
      }, []);

  return (
    <Box
        ref={ref}
        sx={{
            width: '100%',
            top: 0, 
            left: 0, 
            height: '100vh', // Adjust the height as needed
            backgroundColor: 'transparent',
            //backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${Stars})`,
            backgroundSize: 'cover',
            //background: 'radial-gradient(at 50% 100%, #873740 0%, #272730 40%, #171720 80%, #070710 100%)',
            //border: '20px solid #ffffff',
            boxSizing: 'border-box',
            alignContent: 'center',
            zIndex: 15,
            position: "absolute",
            overflow: 'hidden',
        }}
        id="home-section"
    >
       <motion.div style={{y: backgroundY}}>
        <Grid container>
            <Grid item xs={12}>
            
                    <Typography 
                        variant="h3" 
                        align='center'
                        sx={{ 
                            color: 'white',
                            fontSize: {
                                xs: '16px',
                                sm: '18px',
                                md: '18px',
                                lg: '18px'
                            },
                            fontFamily: 'Roboto, sans-serif',
                            fontWeight: 'bold',
                            mb: 2,
                            display: 'flex',   // Add display flex to align items horizontally
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <style>{blinkingAnimation}</style>
                        <div style={blinkingGreenLightStyle}/>
                    OPEN FOR NEW PROJECTS
                    </Typography>
           
            </Grid>

            <Grid item xs={12} >
                    <Typography 
                        variant="h3" 
                        align='center'
                        sx={{ 
                            color: 'white',
                            fontSize: {
                                xs: '40px',
                                sm: '60px',
                                md: '97px',
                                lg: '97px'
                            },
                            fontFamily: 'Roboto, sans-serif',
                            fontWeight: 'bold',
                            mb: {xs: 0, md: -2,},
                        }}
                    >
                    Crafting Stellar 
                    </Typography>
                    <Typography 
                        variant="h3" 
                        align='center'
                        sx={{ 
                            color: 'white',
                            fontSize: {
                                xs: '40px',
                                sm: '60px',
                                md: '97px',
                                lg: '97px'
                            },
                            fontFamily: 'Roboto, sans-serif',
                            fontWeight: 'bold',
                            mb: {xs: 2, md: 4,},
                        }}
                    >
                    Web Experiences
                    </Typography>


            
                    <Typography 
                        variant="h3" 
                        align='center'
                        sx={{ 
                            color: 'white',
                            fontSize: {
                                xs: '20px',
                                sm: '24px',
                                md: '24px',
                                lg: '24px'
                            },
                            fontFamily: 'Roboto, sans-serif',
                            //fontWeight: 'bold',
                            mb: .5,
                        }}
                    >
                    Welcome to Mammoth Websites!
                    </Typography>
                    <Typography 
                        variant="h3" 
                        align='center'
                        sx={{ 
                            color: 'white',
                            fontSize: {
                                xs: '20px',
                                sm: '24px',
                                md: '24px',
                                lg: '24px'
                            },
                            fontFamily: 'Roboto, sans-serif',
                            //fontWeight: 'bold',
                        }}
                    >
                    Where Cosmic designs translate to Mammoth results.
                    </Typography>
    

                    <Button
                        variant="outlined"
                        sx={{
                            borderRadius: '999px', // Make the button oval
                            background: 'transparent', // Set the background to transparent
                            color: 'white', // Set the text color to white
                            border: '3px solid white', // Set border to transparent initially
                            '&:hover': {
                                backgroundColor: 'white', // Change background color on hover
                                border: '3px solid white', // Change border color on hover
                                color: 'black',
                            },
                            width: { xs: '200px', md: '250px', lg: '250px', xl: '250px' },
                            height: { xs: '50px', md: '60px', lg: '60px', xl: '60px' },
                            mt: { xs: 3, md: 5 },
                            //cursor: 'pointer',
                            //zIndex: 10000, 
                            mx: 'auto',
                            display: 'block',
                        }}
                        onClick={() => {
                            // Smooth scroll to the target element
                            document.querySelector("#services-section").scrollIntoView({
                                behavior: "smooth"
                            });
                        }}
                    >
                    
                        <Typography
                            sx={{
                                fontWeight: 300,
                                letterSpacing: '.1rem',
                                fontSize: { xs: '1rem', md: '1.4rem', lg: '1.4rem', xl: '1.4rem'} //resize based on screensize
                            }}
                        >
                            Learn More
                        </Typography>
                   
                </Button>

                
            </Grid>
            
        </Grid>
        </motion.div>
    </Box>
    
  );
};

export default HomeLandingSection;
