import React from 'react';
import {
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Avatar,
    Button,
    Tooltip,
    MenuItem,
    MenuList,
    Popover,
    List,
    ListItem,
    ListItemText,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    Grid,
    Drawer,
    Hidden
  } from '@mui/material';


const FooterSection = () => {
    const currentYear = new Date().getFullYear(); 
    return (
        
        <Box sx={{backgroundColor: '#0f0f0f'}}>
            <Grid container sx={{pb: 3}}>       
                <Grid item xs={12}>
                    <Typography 
                        align='center'
                        sx={{ 
                            color: 'white',
                            fontSize: {
                                xs: '1rem',
                                sm: '1rem',
                                md: '1rem',
                                lg: '1rem'
                            },
                            fontFamily: 'Montserrat',
                            fontWeight: 'bold',
                            //mb: 3,
                        }}
                    >
                    &copy; {currentYear}  Mammoth Websites | All Rights Reserved
                    </Typography>
                </Grid>
            </Grid>
        
        </Box>
    );
};

export default FooterSection;