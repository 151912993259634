/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/Models/snowy_mountain.glb 
Author: James Lucas (https://sketchfab.com/jameslucas)
License: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
Source: https://sketchfab.com/3d-models/snowy-mountain-09ed3babc1784b9a8a40b62d4f765f66
Title: Snowy Mountain
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import {motion, useScroll, useTransform} from "framer-motion"

const Mountain = (props) => {
  const { nodes, materials, animations } = useGLTF('./Models/snowy_mountain.glb')
  const scaleFactor = 0.25; 

  const ref = useRef(null);
  const {scrollYProgress} = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });
  const cameraY = useTransform(scrollYProgress, [0, 1], [-20, -20]);
  //console.log("Mnt Cam:", cameraY.get())
  
  return (
    <group  {...props} dispose={null}  scale={[scaleFactor, scaleFactor, scaleFactor]} position={[-5, cameraY.get(), -5]}>
      <group position={[0, -139.125, 140.836]} rotation={[-Math.PI, 0, 0]}>
        <mesh geometry={nodes.Object_2.geometry} material={materials.blinn1SG} />
        <mesh geometry={nodes.Object_3.geometry} material={materials.blinn1SG} />
        <mesh geometry={nodes.Object_4.geometry} material={materials.blinn1SG} />
        <mesh geometry={nodes.Object_5.geometry} material={materials.blinn1SG} />
        <mesh geometry={nodes.Object_6.geometry} material={materials.blinn1SG} />
        <mesh geometry={nodes.Object_7.geometry} material={materials.blinn1SG} />
        <mesh geometry={nodes.Object_8.geometry} material={materials.blinn1SG} />
        <mesh geometry={nodes.Object_9.geometry} material={materials.blinn1SG} />
      </group>
    </group>
  )
}

export default Mountain;
useGLTF.preload('./Models/snowy_mountain.glb')
