import { useState } from 'react';
import styles from './style.module.scss';
import Titles from './titles/titles.js';
import Descriptions from './descriptions/descriptions.js';
import { Typography } from '@mui/material';

const data = [
    {
        title: "UI/UX",
        description: "User-friendly & tailored interfaces that drive business growth & style",
        speed: 0.2
    },
    {
        title: "Front-End",
        description: "Dynamic experiences with engaging content, interactivity, and responsiveness",
        speed: 0.67
    },
    {
        title: "Back-End",
        description: "Seamless designs with data management, optimizing storage, and scalability",
        speed: 0.8
    },
    {
        title: "Testing",
        description: "Optimize performance ensuring device compatibility for a smooth user experience",
        speed: 0.8
    },
    {
        title: "Deploy",
        description: "Expertly transition to a live environment with enhanced settings & SEO strategies",
        speed: 0.8
    },
    {
        title: "Upkeep",
        description: "Ensuring website maintenance with post-launch support & security",
        speed: 0.8
    }
]

export default function Projects() {
    const [selectedProject, setSelectedProject] = useState(null)
    return (
        <div className={styles.container}>
            <Titles data={data} setSelectedProject={setSelectedProject}/>
            <Descriptions data={data} selectedProject={selectedProject}/>
        </div>
    )
}
