import React from 'react';
import { motion } from 'framer-motion';

import {
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Avatar,
    Button,
    Tooltip,
    MenuItem,
    MenuList,
    Popover,
    List,
    ListItem,
    ListItemText,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    Grid,
    Drawer,
    Hidden
  } from '@mui/material';

import logo from '../../Images/logos/logo_resized-removebg.png';

const LogoSection = () => {
  return (
    <Box
        sx={{
            borderRadius: '12px',
            padding: '20px',
            backgroundColor: '#1E1E1E',
            textAlign: 'center',
        }}
    >
     <Box
        component="img"
        src={logo}
        alt="Mammoth Logo"
        sx={{
          width: {xs: "200px", sm: "300px", md: '400px'}
            //maxWidth: { xs: 'auto', md: '75%', lg: '100%' },
            //height: 'auto',
        }}
    />
    
  </Box>     
  );
};

export default LogoSection;
