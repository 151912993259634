import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem.tsx";
import {
  List,
  ListItemButton,
  Typography,
  Grid,
} from '@mui/material';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const variants2 = {
  open: {
    y: 150,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    }
  },
  closed: {
    y: 500,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export const Navigation = ({isOpen}) => {
    const timeZone = 'America/New_York'; // For example, New York timezone
    const date = new Date();
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone,
      hour12: true, // Use 12-hour format
      hour: 'numeric', // Display hours
      minute: 'numeric', // Display minutes
      //second: 'numeric' // Display seconds
    });
    const timeString = formatter.format(date);
  
    return (
      <motion.ul variants={variants} >
     
          <MenuItem open={isOpen}/>

          <motion.div
            variants={variants2}
          >
            
            <Grid container xs={12} sx={{ mt: 'auto' }}>
              <Grid container item xs={12} sx={{ justifyContent: 'flex-end' }}>
                <Grid item xs={12}>
                  <Typography
                    align="left"
                    sx={{
                      fontSize: { xs: '1rem', md: '1rem', lg: '1rem', xl: '1rem' },
                      color: 'gray',
                    }}
                  >
                    LOCAL
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography
                    align="left"
                    sx={{
                      fontSize: { xs: '1.25rem', md: '1.25rem', lg: '1.25rem', xl: '1.25rem' },
                    }}
                  >
                    Maryland
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    align="left"
                    sx={{
                      fontSize: { xs: '1.25rem', md: '1.25rem', lg: '1.25rem', xl: '1.25rem' },
                      ml: { xs: '1.25rem', md: '1.25rem', lg: -4, xl: '1.25rem' },
                    }}
                  >
                    {timeString}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

           
        </motion.div>
       
      </motion.ul>
    );
};

