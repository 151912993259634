/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/Models/MammothSolo.glb 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function MammothModel(props) {
  const { nodes, materials } = useGLTF('./Models/MammothSolo.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[-0.65, 0.046, 0.109]} rotation={[0, -Math.PI / 2, 0]} scale={1.074}>
        <mesh geometry={nodes.Plane.geometry} material={materials.Wool} />
        <mesh geometry={nodes.Plane_1.geometry} material={materials.Tusks} />
      </group>
    </group>
  )
}

useGLTF.preload('./Models/MammothSolo.glb')
