import React from "react";
import styles from "./bubble.module.css";
import { Grid, Typography } from "@mui/material";

const BubbleTextSection = () => {
  return (
    <div className="grid h-screen place-content-center bg-black">
      <BubbleText />
    </div>
  );
};

const BubbleText = () => {
  return (
    <Typography
        variant="h2"
        align="center"
        sx={{ 
            fontSize: {xs: "4rem", sm: "9rem", md: "12rem"}, 
            fontWeight: 900, 
            //color: "#b7ab98" 
            color: 'white',
            mb: -3,
            zIndex: 10,
        }}
    >
      {"Services".split("").map((child, idx) => (
        <span className={styles.hoverText} key={idx}>
          {child}
        </span>
      ))}
    </Typography>
  );
};

export default BubbleTextSection;