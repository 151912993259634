import React from 'react';
import { 
    Dialog, 
    DialogContent, 
    Typography,
    Grid,
    Card,
    CardMedia,
    IconButton,
    Box,
    Button,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import MainPic from '../../../Images/Nazarene/Main.png';

import { SwipeCarouselSection } from './SwipeCarousel';
import CarouselSection from './Carousel';

const DialogSection = ({ open, onClose }) => {
    
    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" scroll='paper' PaperProps={{ sx:{ borderRadius: '20px'}}}>
            <DialogContent sx={{ borderRadius: '30px', padding: '20px', pt:0 }}>
                <Box >
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card
                                sx={{
                                    width: 'auto',
                                    borderRadius: '10px',
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={MainPic}
                                    sx={{
                                        height: 'auto',
                                        maxHeight: '600px',
                                    }}
                                />
                            </Card>
                        </Grid>


                        <Grid item xs={12} md={6} sx={{p: {xs:2, md: 5} }}>
                            <Grid item xs={12}>
                                <Typography 
                                    sx={{ 
                                        textAlign: {xs:'center', md: 'left'},
                                        color: '#0f0f0f',
                                        fontSize: {
                                            xs: '2.5rem',
                                            sm: '3.5rem',
                                            md: '2.5rem',
                                            lg: '3.25rem'
                                        },
                                        //fontFamily: 'Montserrat',
                                        fontWeight: 'bold',
                                        //mb: 3,
                                    }}
                                >
                                Nazarene Baptist
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                     sx={{ 
                                        textAlign: {xs:'center', md: 'left'},
                                        color: '#0f0f0f',
                                        fontSize: {
                                            xs: '2.5rem',
                                            sm: '2.5rem',
                                            md: '2.5rem',
                                            lg: '2.5rem'
                                        },
                                        //fontFamily: 'Montserrat',
                                        //fontWeight: 'bold',
                                        mt: -1.25,
                                    }}
                                >
                                Church
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                    sx={{ 
                                        textAlign: {xs:'center', md: 'left'},
                                        color: '#F8AD13',
                                        fontSize: {
                                            xs: '1.3rem',
                                            sm: '1.7rem',
                                            md: '1.2rem',
                                            lg: '1.2rem'
                                        },
                                        fontFamily: 'Montserrat',
                                        fontWeight: 'bold',
                                        //mt: -1,
                                        //ml: .25,
                                    }}
                                >
                                Website Development 
                                </Typography>
                            </Grid>
                           
                            {/*<Grid item xs={12} sx={{textAlign: 'left'}}>
                                <Button
                                    variant="outlined"
                                    align="left"
                                    sx={{
                                        //borderRadius: '999px', // Make the button oval
                                        background: 'black', // Set the background to transparent
                                        color: 'white', // Set the text color to white
                                        border: '3px solid black', // Set border to transparent initially
                                        '&:hover': {
                                            backgroundColor: 'white', // Change background color on hover
                                            border: '3px solid black', // Change border color on hover
                                            color: 'black',
                                        },
                                        width: { xs: '275px', md: '250px', lg: '225px', xl: '200px' },
                                        height: { xs: '60px', md: '50px', lg: '40px', xl: '50px' },
                                        mt: 2,
                                    }}
                                    href="https://skyymyles.com" 
                                    target="_blank"
                                >
                                        <Typography
                                            sx={{
                                                fontWeight: 300,
                                                letterSpacing: '.1rem',
                                                fontSize: { xs: '1.2rem', md: '1rem', lg: '1rem', xl: '1.4rem'}, //resize based on screensize
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            View Project
                                            
                                        </Typography>
                                        <ArrowForwardIcon sx={{ ml: 1 }} />
                                </Button>
                             </Grid>*/}
                        </Grid>

                        <Grid item xs={12} md={6} sx={{p: {xs:2, md: 5} }}>
                            <Grid item xs={12}>
                                <Typography 
                                    sx={{ 
                                        textAlign: {xs:'center', md: 'left'},
                                        color: '#0f0f0f',
                                        fontSize: {
                                            xs: '1.25rem',
                                            sm: '1.5rem',
                                            md: '1.25rem',
                                            lg: '1.25rem'
                                        },
                                        //fontFamily: 'Montserrat',
                                        fontWeight: 'bold',
                                        mt: 2,
                                    }}
                                >
                                    In order to allow Nazarene Baptist Church's members to stay informed and promote outreach,
                                    We built visually informative website to assist new and returning members see the chruch's impact and values.
                                    The Nazarene Baptist Church website includes dynamic features such as smart forms and other interactive elements.
                                </Typography>
                            </Grid>
                        </Grid>


                        {/*<Grid item xs={12} sx={{p: {xs: -2, md: '40px'}, mb: 5}}>
                            <CarouselSection/>
                        </Grid>*/}
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DialogSection;
