/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/Models/the_moon.glb 
Author: SebastianSosnowski (https://sketchfab.com/SebastianSosnowski)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/the-moon-9916fcec59f04b07b3e8d7f077dc3ded
Title: The Moon
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

const MoonModel2 = (props) => {
  const { nodes, materials, animations } = useGLTF('./Models/the_moon.glb')
  
  const scaleFactor = 2.25; 

  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} position={[-45, 25, 0]} scale={[scaleFactor, scaleFactor, scaleFactor]}>
        <mesh geometry={nodes.defaultMaterial.geometry} material={materials.Material__50} rotation={[Math.PI / 2, 0, 0]} />
      </group>
    </group>
  )
}

export default MoonModel2;
useGLTF.preload('./Models/the_moon.glb')
