import * as React from "react";
import { motion } from "framer-motion";

import {
  List,
  ListItemButton,
  Typography
} from '@mui/material';

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export const MenuItem = ({open}) => {

  return (
   
    <List  sx={{ flexDirection: 'column', mt: -17,}}>
      
      {['Home', 'About', 'Portfolio', 'Services', 'Contact'].map((text, index) => (
        <motion.li
          key={text}
          variants={variants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <ListItemButton
            component="a"
            href={`#${text.toLowerCase()}-section`}
              sx={{
                borderRadius: '0px',
                width: '200px',
                height:  '70px' ,
                //flex: 1,
                '&:hover': {
                  backgroundColor: 'black', // Change background color on hover
                  border: '3px solid black', // Change border color on hover
                  color: 'white',
                },
                //zIndex: 1000,
                //position: 'relative'
                cursor: open ? 'pointer' : 'default',
              }}
              disabled={!open}
              
            >
              <Typography 
                sx={{
                  zIndex: 1001,
                  fontSize: { xs:'2.25rem', md: '2.25rem', lg: '2.25rem', xl: '2.25rem'},
                }}
                
              >
                {text}
              </Typography>
            </ListItemButton>
        </motion.li>
      ))}
    </List>
  
  );
};
