import * as React from "react";
import { motion } from "framer-motion";

import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle, isOpen }) => (
    <Button
      //variant="outlined"
      sx={{
        //borderRadius: '999px', // Make the button oval
        //background: isOpen ? 'tranparent' : '#F8AD13', // Set the background to transparent
        color: isOpen ? '#F8AD13' : 'white', // Set the text color to white
        //border: isOpen ? 'transparent' : '3px solid #F8AD13', // Set border to transparent initially
        '&:hover': {
          //backgroundColor: isOpen ? 'transparent' : 'white', // Change background color on hover
          //border: isOpen ? 'transparent' : '3px solid black', // Change border color on hover
          color: isOpen ? '#F8AD13' : '#F8AD13',
        },
        //width: isOpen ? { xs:'25px', md: '25px', lg: '25px', xl: '25px' } : { xs:'130px', md: '130px', lg: '130px', xl: '130px' },
        //height: isOpen ? { xs: '25px', md: '25px', lg: '25px', xl: '25px' } : { xs: '40px', md: '40px', lg: '40px', xl: '40px' },
        //right: isOpen ? '0px' : '0px',
        //top: isOpen ? '40px' : '40px',
      }}
      onClick={toggle}
    >
      <Typography
        /*sx={{
          fontWeight: 600,
          letterSpacing: '.1rem',
          fontSize: { md: '1rem', lg: '1.4rem', xl: '1.4rem'},
          fontFamily: 'Montserrat',
        }}*/
      >
        {isOpen ? 
          <CloseIcon sx={{ fontSize: { xs: 20, md: 40 } }} /> : 
          <MenuIcon sx={{ fontSize: { xs: 20, md: 40 } }} />
        }
      </Typography>
       
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" }
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" }
        }}
      />
    
  </Button>
);
