import React from 'react';
import { motion } from 'framer-motion';

import {
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Avatar,
    Button,
    Tooltip,
    MenuItem,
    MenuList,
    Popover,
    List,
    ListItem,
    ListItemText,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    Grid,
    Drawer,
    Hidden
  } from '@mui/material';

import FloatingPhoneSection from './FloatingPhone';

//Sections
import MyContactSection from './MyContact';
import LogoSection from './Logo';
import ContactFormSection from './ContactForm';

const ContactSection = () => {
  return (
    
    <Box sx={{backgroundColor: '#0f0f0f'}} id="contact-section">
        <Grid container sx={{p: 5}}>       
          
          <Grid item xs={12} md={6}>
            <Grid item xs={12}>
              <MyContactSection/>      
            </Grid>

            <Grid item xs={12} sx={{ mt: 3, mb: {xs: 3, md: 0} }}>
              <LogoSection/>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} >
            <ContactFormSection/>
          </Grid>
        </Grid>
      
    </Box>
  );
};

export default ContactSection;
