import './App.css';
import { useEffect } from 'react';
import Lenis from '@studio-freight/lenis'

import ButtonAppBar from './Components/AppBar/AppBar.js'; 
import { PlanetAnimation } from './Components/PlanetScroll';
import ServicesSection from './Components/Services/Services';
import HomeLandingSection from './Components/HomeLanding';
//import AboutSection from './Components/About';
//import AboutSection from './Components/About2';
import AboutSection from './Components/About3';
import PortfolioSection  from './Components/Portfolio/Portfolio.js';
import ContactSection from './Components/Contact/Contact.js';
import FooterSection from './Components/Footer.js';
import { MyMovingBkg } from './Components/MvBackground/MovingBkg.js';
import CloudMntSection from './Components/MvBackground/CloudMnt.js';
import StickyPage from './Components/StickyPointer/page.js';
import SpotlightMammoth from './Components/Spotlight.js';
import ZoomParallaxElement from './Components/ZoomParallax/zoompara.jsx';
import BounceCurveElement from './Components/BounceCurve/bounceCurve.js';

function App() {

  useEffect( () => {
      const lenis = new Lenis()
    
      function raf(time) {
          lenis.raf(time)
          requestAnimationFrame(raf)
      }

      requestAnimationFrame(raf)
  },[])

  return (
    <div>
      {/*<SpotlightMammoth/>
      <StickyPage/>*/}
      <CloudMntSection/>
      <ButtonAppBar/>
     
      <HomeLandingSection/>
      <AboutSection/>
      <BounceCurveElement/>
       {/*<PlanetAnimation/>
      <ZoomParallaxElement/>*/}
      <PortfolioSection/>
      <ServicesSection/>
      <ContactSection/>
      <FooterSection/>
     
    </div>
  );
}

export default App;