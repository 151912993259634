/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/Models/cloud_ring.glb 
Author: RandyGF (https://sketchfab.com/RandyGF)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/cloud-ring-27897026b0a24dfe992ca761a4029d01
Title: Cloud Ring
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

const Clouds = (props) => {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('./Models/cloud_ring.glb')
  const { actions, names } = useAnimations(animations, group)
  //console.log("Cloud Ani:", names);

  const scaleFactor = 40; 

  useEffect(() => {
    actions[names[0]].reset().fadeIn(0.5).play();
    actions[names[0]].setEffectiveTimeScale(0.05); 
  }, [])

  //Position: +left/right-, +up/down-, +forward/back-
  return (
    <group ref={group} {...props} dispose={null} scale={[scaleFactor, scaleFactor, scaleFactor]}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group name="root">
            <group name="GLTF_SceneRootNode" rotation={[Math.PI / 2, 0, 0]} >
              <group name="Cloud_GN001_2" position={[0, -.2, 0]}>
                <mesh name="Object_4" geometry={nodes.Object_4.geometry} material={materials.Cloud} />
              </group>
              <group name="Cloud_GN002_3" rotation={[0, -0.016, 0]} position={[0, -.65, 0]}>
                <mesh name="Object_6" geometry={nodes.Object_6.geometry} material={materials.Cloud} />
              </group>
             <group name="Cloud_GN003_4" rotation={[0, -1.031, 0]} position={[0, -1.5, 0]}>
                <mesh name="Object_8" geometry={nodes.Object_8.geometry} material={materials.Cloud} />
              </group>

              <group name="Cloud_GN002_3" rotation={[0, 0, 0]} position={[0, -.7, 2]}> 
                <mesh name="Object_6" geometry={nodes.Object_6.geometry} material={materials.Cloud} />
              </group>
              <group name="Cloud_GN002_3" rotation={[0, 0, 0]} position={[.5, -.6, 1]}> 
                <mesh name="Object_6" geometry={nodes.Object_6.geometry} material={materials.Cloud} />
              </group>
              <group name="Cloud_GN002_3" rotation={[0, 0, 0]} position={[1, -.65, 1]}> 
                <mesh name="Object_6" geometry={nodes.Object_6.geometry} material={materials.Cloud} />
  </group>
              
             
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

export default Clouds;
useGLTF.preload('./Models/cloud_ring.glb')
