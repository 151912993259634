import React, { useState } from 'react';
import { 
    Box,
    Card,
    CardMedia,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContent,
} from '@mui/material';

import MainPic from '../../../Images/OldMammoth/Main.png'
import DialogSection from './OldMammothDialog';

const OldMammothSection = () => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return (
        <Box sx={{backgroundColor: '#0f0f0f', p: 5, ml: {xs:0, md: 5} }}>
            <Grid container>       
                            
                {/*Medium+ Breakpoint Section*/}
                <Grid item xs={12} md={6} sx={{p:{xs:2 , md: 7}, mt:5, display: { xs: 'none', md: 'block' }}}>
                    <Grid item xs={12}>
                        <Typography 
                            align='right'
                            sx={{ 
                                color: 'white',
                                fontSize: {
                                    xs: '2.25rem',
                                    sm: '3rem',
                                    md: '2.25rem',
                                    lg: '2.75rem'
                                },
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                //mb: 3,
                            }}
                        >
                        Mammoth Websites
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography 
                            align='right'
                            sx={{ 
                                color: 'white',
                                fontSize: {
                                    xs: '1.25rem',
                                    sm: '2rem',
                                    md: '1.5rem',
                                    lg: '2rem'
                                },
                                fontFamily: 'Montserrat',
                                //fontWeight: 'bold',
                                mt: {xs: 0, md: -1},
                            }}
                        >
                       2023 Website Design
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography 
                            align='right'
                            sx={{ 
                                color: '#F8AD13',
                                fontSize: {
                                    xs: '1.1rem',
                                    sm: '1.5rem',
                                    md: '1.1rem',
                                    lg: '1.2rem'
                                },
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                mt: {xs: 0, md: -1},
                                ml: .25,
                            }}
                        >
                        Website Development 
                        
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: 'right'}}>
                        <Button
                            variant="outlined"
                            align="right"
                            sx={{
                                borderRadius: '999px', // Make the button oval
                                background: 'transparent', // Set the background to transparent
                                color: 'white', // Set the text color to white
                                border: '3px solid white', // Set border to transparent initially
                                '&:hover': {
                                    backgroundColor: 'white', // Change background color on hover
                                    border: '3px solid white', // Change border color on hover
                                    color: 'black',
                                },
                                width: { xs: '225px', md: '225px', lg: '225px', xl: '225px' },
                                height: { xs: '50px', md: '50px', lg: '50px', xl: '50px' },
                                mt: 1.25,
                            }}
                            onClick={handleClickOpen}
                        >
                                <Typography
                                    sx={{
                                        fontWeight: 300,
                                        letterSpacing: '.1rem',
                                        fontSize: {xs: '1.4rem', md: '1.2rem', lg: '1.2rem', xl: '1.4rem'} //resize based on screensize
                                    }}
                                >
                                    Check It Out
                                </Typography>
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <DialogSection open={open} onClose={handleClose}/>
                    </Grid>
                    
                </Grid>


                <Grid item xs={12} md={6} >
                    <Card
                        align="right"
                        sx={{
                            width: {md: '500px', lg: '600px', xl: '700px'},
                            borderRadius: '10px',
                            alignContent: "right"
                        }}
                    >
                        <CardMedia
                            component="img"
                            image={MainPic}
                            sx={{
                                height: {md: '400px', lg: '400px', xl: '400px'},
                                objectPosition: 'left',
                            }}
                        />
                    </Card>
                </Grid>

                {/*Small Breakpoint Section*/}
                <Grid item xs={12} md={6} sx={{p:{xs:2 , md: 4, lg: 7}, mt:{xs: 2, md: 5}, display: { xs: 'block', md: 'none' }}}>
                    <Grid item xs={12}>
                        <Typography 
                            align='right'
                            sx={{ 
                                color: 'white',
                                fontSize: {
                                    xs: '2.25rem',
                                    sm: '3rem',
                                    md: '2.25rem',
                                    lg: '2.75rem'
                                },
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                //mb: 3,
                            }}
                        >
                        Mammoth Websites
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography 
                            align='right'
                            sx={{ 
                                color: 'white',
                                fontSize: {
                                    xs: '1.5rem',
                                    sm: '2rem',
                                    md: '1.5rem',
                                    lg: '2rem'
                                },
                                fontFamily: 'Montserrat',
                                //fontWeight: 'bold',
                                mt: {xs: 0, md: -1},
                            }}
                        >
                       2023 Website Design
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography 
                            align='right'
                            sx={{ 
                                color: '#F8AD13',
                                fontSize: {
                                    xs: '1.1rem',
                                    sm: '1.5rem',
                                    md: '1.1rem',
                                    lg: '1.2rem'
                                },
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                mt: {xs: 0, md: -1},
                                ml: .25,
                            }}
                        >
                        Website Development 
                        
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: 'right'}}>
                        <Button
                            variant="outlined"
                            align="right"
                            sx={{
                                borderRadius: '999px', // Make the button oval
                                background: 'transparent', // Set the background to transparent
                                color: 'white', // Set the text color to white
                                border: '3px solid white', // Set border to transparent initially
                                '&:hover': {
                                    backgroundColor: 'white', // Change background color on hover
                                    border: '3px solid white', // Change border color on hover
                                    color: 'black',
                                },
                                width: { xs: '225px', md: '225px', lg: '225px', xl: '225px' },
                                height: { xs: '50px', md: '50px', lg: '50px', xl: '50px' },
                                mt: 1.25,
                            }}
                            onClick={handleClickOpen}
                        >
                                <Typography
                                    sx={{
                                        fontWeight: 300,
                                        letterSpacing: '.1rem',
                                        fontSize: {xs: '1.4rem', md: '1.2rem', lg: '1.2rem', xl: '1.4rem'} //resize based on screensize
                                    }}
                                >
                                    Check It Out
                                </Typography>
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <DialogSection open={open} onClose={handleClose}/>
                    </Grid>
                    
                </Grid>


            </Grid>        
        </Box>
    );
}

export default OldMammothSection;
