import './styles.css';
import * as React from 'react';
import { useRef, useEffect } from "react";
import { motion, sync, useCycle } from "framer-motion";


// MUI Components
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  MenuList,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Grid,
  Drawer,
  AppBar,
  Divider,
  SwipeableDrawer,
} from '@mui/material';

//Images
import logo from '../../Images/logos/logo_resized-removebg.png';

//App Imports
import { useDimensions } from "./use-dimensions.ts";
import { MenuToggle } from "./MenuToggle.tsx";
import { Navigation } from "./Navigation.tsx";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 115px 45px)`,
    backgroundColor: "#fff",
    transition: {
      type: "spring",
      stiffness: 50,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(25px at 200px 45px)",
    backgroundColor: "rgba(0, 0, 0, 0)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

export default function ButtonAppBar() {
  //const [open, setOpen] = React.useState(false);
  const [isOpen, toggleOpen] = React.useState(false);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        toggleOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box sx={{ flexGrow: 1}}>
      <AppBar sx={{backgroundColor: "transparent", boxShadow: 'none',  mt: '25px', px: 3, }} >
        <Toolbar disableGutters>
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              borderRadius: '0 0 20px 0', 
            }}
          >
            <Avatar
              component="a"
              href={'home-section'}
              src={logo}
              alt="Logo"
              variant='square'
              sx={{
                width: { xs: '120px', md: '100px', lg: '120px', xl: '120px'},
                height: { xs: '70px', md: '70px', lg: '70px', xl: '100px'},
                marginRight: '10px',
                top: '7px',
                mb: 3,
                ml: 1
              }} 
            />
          </Box>
          
          <Box sx={{ flexGrow: 1, }} />
          
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              borderRadius: '0 0 0 20px', 
              py: 3,
            }}
          >
            
              <MenuToggle toggle={() => toggleOpen(true)} isOpen={isOpen} />

          </Box>
        </Toolbar>
      </AppBar>
      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
        style={{ zIndex: isOpen ? 100 : 0 }}
      >
        <motion.div className="background" variants={sidebar} />
        <Navigation isOpen={isOpen}/>
        
      </motion.nav>
    </Box>
  );
}
