import React, { Suspense, useRef } from 'react'
import { Canvas, render, useThree } from '@react-three/fiber'
import { Loader, useGLTF, OrbitControls, PerspectiveCamera, Stars } from '@react-three/drei'
import { useScroll, useTransform, motion} from 'framer-motion';

import {
  Box,
} from '@mui/material';


export function Model(props) {
  const { nodes, materials } = useGLTF('/IcyPlanetGLB2.glb')

  console.log("Prop:", props.prog.get())
  return (
    <group {...props} dispose={null} position={[-2, 0, 2]} rotation={[0, .5, 0]} scale={1.25}>
      <mesh geometry={nodes.planet.geometry} material={materials.planet} position={[-0.003, -6.331, -0.024]} rotation={[-0.078, 0.473, 0.622]} scale={7} />
      <group position={[14.151, -1.397, 1.701]} rotation={[1.999, -0.572, 1.733]} scale={0.235}>
        <mesh geometry={nodes.Cube005.geometry} material={materials.snow} />
        <mesh geometry={nodes.Cube005_1.geometry} material={materials.stone1} />
        <mesh geometry={nodes.Cube005_2.geometry} material={materials.stone3} />
      </group>
      <group position={[-1.566, -2.639, 5.368]} rotation={[-2.888, -0.862, -2.039]} scale={0.183}>
        <mesh geometry={nodes.Cube009.geometry} material={materials.stone3} />
        <mesh geometry={nodes.Cube009_1.geometry} material={materials.stone2} />
        <mesh geometry={nodes.Cube009_2.geometry} material={materials.stone1} />
      </group>
      <mesh geometry={nodes.planet001.geometry} material={materials.snow} position={[-0.003, -6.331, -0.024]} rotation={[-0.078, 0.473, 0.622]} scale={7} />
      <mesh geometry={nodes.planet002.geometry} material={materials.snow} position={[-0.003, -6.331, -0.024]} rotation={[-0.078, 0.473, 0.622]} scale={7} />
      <group position={[-0.65, 2.527, 0.109]} rotation={[0, -Math.PI / 2, 0]} scale={1.074}>
        <mesh geometry={nodes.Plane.geometry} material={materials.Wool} />
        <mesh geometry={nodes.Plane_1.geometry} material={materials.Tusks} />
      </group>
    </group>
  )
}

export const PlanetAnimation = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
      target: ref,
      offset: ['start start', 'end end']
  })
  const scale = useTransform(scrollYProgress, [0, 1], [1, 4]);
  
  return (
    <Box
      sx={{
        position: 'relative',
        width: 'auto',
        height: '100%', // Adjust the height as needed
        backgroundColor: '#0f0f0f',
        zIndex: 1,
        minWidth: 0,
        minHeight: 0,
        overflow: 'hidden'
      }}
      
    >

      <Canvas dpr={[1.5, 2]} linear resize={null} ref={ref}>
        <ambientLight intensity={5} />
        <PerspectiveCamera makeDefault position={[0, 0, 20]} fov={75}>
          <pointLight intensity={5} position={[0, 0, 20]} />
          <spotLight castShadow intensity={10000} angle={0.2} penumbra={1} position={[0, 0, 20]} shadow-mapSize={[1024, 1024]} shadow-bias={-0.0001} />
        </PerspectiveCamera>
        <Suspense fallback={null}>
          <Model prog={scale}/>
        </Suspense>
       {/*<OrbitControls autoRotate autoRotateSpeed={1.5} enabled={true} enablePan={false} enableZoom={false} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2} />*/}
      </Canvas>

      <div className="layer" />
      <Loader />
      
    </Box>
  )
}
