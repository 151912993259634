import React from 'react';
import { Box } from '@mui/material';

import TickerSection from './Ticker';

import SkyyMylesSection from './SkyyMyles/SkyyMylesPortfolio';
import LumberjackSection from './Lumberjack/LumberjackPortfolio';
import NazareneSection from './Nazarene/NazarenePortfolio';
import OldMammothSection from './OldMammoth/OldMammothPortfolio';

const PortfolioSection = () => {

  return (
    <Box sx={{backgroundColor: '#0f0f0f'}} id="portfolio-section">
      <TickerSection/>
      <SkyyMylesSection/>
      <LumberjackSection/>
      <NazareneSection/>
      <OldMammothSection/>
    </Box>
  );
}

export default PortfolioSection;
