import React from 'react';
import { motion } from 'framer-motion';
import styles from './page.module.scss'

import {
    Box,
    Grid,
  } from '@mui/material';

import Earth from './Earth';
import Projects from '../projects/projects';
import BubbleTextSection from './BubbleText/BubbleText';

const ServicesSection = () => {
  return (
    
    <Box sx={{backgroundColor: '#0f0f0f'}} id="services-section">
        <Grid container>       
          <Grid item xs={12} sx={{p:{xs: 2, md: 2}}} >
             <BubbleTextSection/>
          </Grid>

          <Grid item xs={12} className={styles.main} >
              <Earth/>
              <Projects/>
          </Grid>
        </Grid>
      
    </Box>
  );
};

export default ServicesSection;
