'use client'
import styles from './page.module.css'
import { useRef, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
} from '@mui/material';

export default function BounceCurveElement() {

  const path = useRef(null);
  let progress = 0;
  let x = 0.5;
  let time = Math.PI / 2;
  let reqId = null;

  useEffect(() => {
    setPath(progress);
  }, [])

  const setPath = (progress) => {
    const width = window.innerWidth * 0.7;
    path.current.setAttributeNS(null, "d", `M0 250 Q${width * x} ${250 + progress}, ${width} 250`)
  }

  const lerp = (x, y, a) => x * (1 - a) + y * a

  const manageMouseEnter = () => {
    if(reqId){
      cancelAnimationFrame(reqId)
      resetAnimation()
    }
  }

  const manageMouseMove = (e) => {
    const { movementY, clientX } = e;
    const pathBound =  path.current.getBoundingClientRect();
    x = (clientX - pathBound.left) / pathBound.width;
    progress+= movementY
    setPath(progress);
  }

  const manageMouseLeave = () => {
    animateOut();
  }

  const animateOut = () => {
    const newProgress = progress * Math.sin(time);
    progress = lerp(progress, 0, 0.025);
    time+=0.2;
    setPath(newProgress);
    if(Math.abs(progress) > 0.75){
      reqId = requestAnimationFrame(animateOut);
    }
    else{
      resetAnimation();
    }
  }

  const resetAnimation = () => {
    time = Math.PI / 2;
    progress = 0;
  }

  return (
    <div className={styles.container}>
        <div className={styles.body}>
            <Grid container 
                sx={{
                    pt: {xs: 0, sm: 5, md: 10},
                    pb: {xs: 5, sm: 20, md: 20}
                }}
            >
                <Grid item xs={12} >
                    <Typography
                        sx={{
                            textAlign: {xs: "center", md: "center"},
                            color: 'white',
                            fontSize: {
                                xs: '4rem',
                                sm: '5.75rem',
                                md: '6rem',
                                lg: '8rem',
                                xl: '8rem'
                            },
                            fontFamily: 'Montserrat',
                            fontWeight: 900,
                            lineHeight: .82,
                            letterSpacing: {xs:0, md: '-0.03em'},
                            mb: {xs: 2, md: 2},
                            ml: {xs: -3, sm: 0},
                            mr: {xs: -3, sm: 0},
                        }}
                    >
                       WEB DESIGN
                    </Typography>
                </Grid>

                <Grid item xs={12} > 
                    <div className={styles.line}>
                        <div 
                            onMouseEnter={() => {manageMouseEnter()}} 
                            //onTouchStart={() => {manageMouseEnter()}} 
                            onMouseMove={(e) => {manageMouseMove(e)}} 
                            //onTouchMove={(e) => {manageMouseMove(e)}} 
                            onMouseLeave={() => {manageMouseLeave()}} 
                            //onTouchEnd={() => {manageMouseLeave()}} 
                            className={styles.box}
                        ></div>
                        <svg>
                            <path ref={path}></path>
                        </svg>
                    </div>
                </Grid>

                <Grid item xs={12} > 
                    <Typography
                        sx={{
                            textAlign: {xs: "center", md: "center"},
                            color: 'black',
                            fontSize: {
                                xs: '2rem',
                                sm: '2.25rem',
                                md: '2.75rem',
                                lg: '3rem',
                                xl: '3.5rem'
                            },
                            fontFamily: 'Montserrat',
                            fontWeight: 700,
                            lineHeight: 1,
                            letterSpacing: {xs:0, md: '-0.02em'},
                            mb: {xs: 2, md: 2},
                            ml: {xs: -5, sm: 0},
                            mr: {xs: -5, sm: 0},
                        }}
                    >
                       Lets build a website that perfectly represents the vision of your business.
                       From <span style={{ color: '#F8AD13' }}> visually stunning content</span>  to dynamic backbones, 
                       you need a platform that guarantees high-quality performance and results.
                    </Typography>
                </Grid>
            </Grid>
            
            


        </div>
    </div>
  )
}