import React from 'react';
import Ticker from 'framer-motion-ticker';
import { Box, Typography } from '@mui/material';

const TickerSection = () => {
  const portfolios = ['Portfolio', 'Portfolio', 'Portfolio', 'Portfolio', 'Portfolio'];
  const repeatCount = 100; // Adjust this count to control the number of repetitions

  const repeatedPortfolios = Array.from({ length: repeatCount }, (_, index) => portfolios[index % portfolios.length]);

  return (
    <Box sx={{backgroundColor: '#0f0f0f'}}>
      <Ticker duration={300}>
        {repeatedPortfolios.map((item, index) => (
          <Typography
            key={index}
            style={{
              margin: '3rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontWeight: 'bold',
            }}
            sx={{
              fontSize: {
                xs: '6rem',
                sm: '6rem',
                md: '6rem',
                lg: '8rem',
                xl: '9rem'
            },
            }}
          >
            <span style={{ marginRight: '6rem' }}>&#8226;</span> {/* Add dot before each item */}
            {item}
          </Typography>
        ))}
      </Ticker>
    </Box>
  );
}

export default TickerSection;
